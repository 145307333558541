<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">校园生活</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/interestCourse">兴趣社团课程管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item label="所属学校" prop="schoolId">
            <el-select v-model="queryModel.schoolId" size="mini" clearable
            filterable placeholder="请选择" style="width:200px">
              <el-option
                v-for="result in schoolList"
                :key="result.id"
                :label="result.name"
                :value="result.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="学期" prop="termId">
            <el-select v-model="queryModel.termId" 
            clearable filterable placeholder="请选择" style="width:200px" size="mini">
              <el-option
                v-for="result in termList"
                :key="result.id"
                :label="result.termName"
                :value="result.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="开放年级" prop="gradeId">
            <el-select v-model="queryModel.gradeId" clearable filterable 
            placeholder="请选择" style="width:200px" size="mini">
              <el-option
                v-for="result in gradeList"
                :key="result.id"
                :label="result.name"
                :value="result.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="课程名称" prop="courseName">
            <el-input type="text" size="mini" v-model="queryModel.courseName" style="width:200px"></el-input>
        </el-form-item>
        <el-form-item label="是否开放" prop="courseName">
            <el-select v-model="queryModel.active" size="mini">
              <el-option value="1" label="是"></el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
        </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
      @selection-change="handleSelectionChange"
    >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="schoolName" label="学校" width="180">
          <template slot-scope="{row}">
                {{row.course.schoolName}}
          </template>
        </el-table-column>
        <el-table-column prop="termName" label="学期" width="180">
          <template slot-scope="{row}">
                {{row.course.termName}}
          </template>
        </el-table-column>
        <el-table-column prop="sortNo" label="序号" width="100">
          <template slot-scope="{row}">
                {{row.course.sortNo}}
          </template>
        </el-table-column>
        <el-table-column prop="courseName" label="课程名称" width="180">
          <template slot-scope="{row}">
                {{row.course.courseName}}
          </template>
        </el-table-column>
        <el-table-column prop="active" label="是否开放报名" width="200">
          <template slot-scope="{row}">
              <el-switch  v-model="row.course.active" active-color="#13ce66" inactive-color="#ff4949" @change="updateActive(row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="gradeList" label="开放年级" width="180">
            <template slot-scope="{row}">
                <span v-for="(grade,index) in row.gradeList" :key="index">
                    {{index!=0 ? "/" : ""}}{{grade.gradeName}}
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="totalNum" label="招生人数" width="180">
            <template slot-scope="{row}">
                <span v-for="(grade,index) in row.gradeList" :key="index">
                    {{index!=0 ? "/" : ""}}{{grade.totalNum}}
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="stockNum" label="报名人数" width="180">
            <template slot-scope="{row}">
                <span v-for="(grade,index) in row.gradeList" :key="index">
                    {{index!=0 ? "/" : ""}}{{grade.registerNum==null ? 0 : grade.registerNum}}
                </span>
            </template>
        </el-table-column>
        <el-table-column prop="remark" label="课程说明" width="180"></el-table-column>
        <el-table-column prop="chargeList" label="收费项" width="180">
            <template slot-scope="{row}">
                <div v-for="(charge,index) in row.chargeList" :key="index">
                    {{charge.itemName}}
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="收费金额(员)" width="180">
            <template slot-scope="{row}">
                <div v-for="(charge,index) in row.chargeList" :key="index">
                    {{charge.fee}}
                </div>
            </template>
        </el-table-column>
        <el-table-column prop="createTime" label="收费描述" width="180">
            <template slot-scope="{row}">
                <div v-for="(charge,index) in row.chargeList" :key="index">
                    {{charge.remark}}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="250">
            <template slot-scope="{row}">
                <el-link size="mini" type="primary" @click="handleEdit(row)">编辑</el-link>-
                <el-link size="mini" type="primary" @click="handleManageClass(row)">班级管理</el-link>-
                <el-link size="mini" type="primary" @click="handleManageRegister(row)">报名管理</el-link>-
                <el-link size="mini" type="primary" @click="handleDelete(row)">删除</el-link>
            </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
	<interestCourse-detail
    v-if="showModal"
    :businessKey="businessKey"
    :title="modalTitle"
    @close="onDetailModalClose"
	></interestCourse-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import InterestCourseDetail from "./interestCourse-detail";
import interestCourseApi from "@/api/interest/interestCourse";
import companyInfoApi from "@/api/base/companyInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import pageUtil from "@/utils/page";

export default {
  name: "interestCourseList",
  data() {
    var self = this;

    return {
        queryModel:{
            "schoolId": "",
            "termId": "",
            "courseName": "",
            "gradeId": "",
            "active": ""
        },
        loading: false,
        tableData: [],
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
        totalElements: 0,
        field: "",
        direction: "",
        pageSizeList: [10, 20, 30, 50],
        multipleSelection: [],
        showModal: false,
        modalTitle: "",
        businessKey: "",
        schoolList: [],
        termList: [],
        gradeList: [],
        tableHeight: 400,
        showClassModal: false,
        selectedCourse: {}
    };
  },
  methods: {
    changePage(pageIndex) {
        var self = this;

        self.loading = true;

        self.pageIndex = pageIndex;
        var formData = new FormData();

        formData.append("pageIndex", self.pageIndex);
        formData.append("pageSize", self.pageSize);

        if(self.queryModel.schoolId!=null){
            formData.append("schoolId",self.queryModel.schoolId);
        }

        if(self.queryModel.termId!=null){
            formData.append("termId",self.queryModel.termId);
        }

        if(self.queryModel.gradeId!=null){
            formData.append("gradeId",self.queryModel.gradeId);
        }

        if(self.queryModel.courseName!=null){
            formData.append("courseName",self.queryModel.courseName);
        }

        if(self.queryModel.active != null){
            formData.append("active",self.queryModel.active);
        }

        if (this.field != null) {
            formData.append("field", this.field);
        }

        if (this.direction != null) {
            formData.append("direction", this.direction);
        }

        interestCourseApi.pageList(formData).then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        }).catch((error)=>{
            self.loading = false;
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      
      this.$nextTick(()=>{
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
       this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.course.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        interestCourseApi.remove(record.course.id).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            // var index = self.tableData.indexOf(record);
            // self.tableData.splice(index, 1);
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.course.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        interestCourseApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if(refreshed){
        this.changePage(this.pageIndex);
      }
    },
    onClassModalClose(refreshed) {
      //保存成功后回调
      this.showClassModal = false;
      // if(refreshed){
      //   this.changePage(this.pageIndex);
      // }
    },
    handleManageClass(record) {
      //this.selectedCourse = record.course;
      //this.showClassModal = true;
      var path = "/interest/class/List";

      // this.$store.dispatch('tagsView/delView', {
      //   name: "interestClassList",
      //   path: path
      // }).then(({ visitedViews }) => {
        this.$router.push({ 
          path: path,
          query:{
            title: record.course.courseName + "-班级管理",
            courseId: record.course.id
          }
        });
      // });
    },
    handleManageRegister(record){
      var path = "/interest/register/list";

      // this.$store.dispatch('tagsView/delView', {
      //   name: "interestRegisterList",
      //   path: path
      // }).then(({ visitedViews }) => {
        this.$router.push({
          path: path,
          query:{
            title: record.course.courseName + "-报名管理",
            interestCourseId: record.course.id
          }
        });
      // });
    },
    updateActive(record){
      var course  = record.course;

      interestCourseApi.updateActive(course.id,course.active)
      .then(function(response) {
          var jsonData = response.data;

          if (!jsonData.result) {
            this.$message.error(jsonData.message + "");
          }
        });
    }
  },
  created() {
    var self = this;

    //查询学校
    companyInfoApi.treeList({type:"2"}).then(response => {
      var jsonData = response.data;
      this.schoolList = jsonData.data;
    });

    //查询学期
    interestCourseApi.queryTermList("").then(response=>{
        var jsonData = response.data;
        this.termList = jsonData.data;
    });
  },
  watch: {
    "queryModel.schoolId": function(newVal) {
        //查询学期
        interestCourseApi.queryTermList(newVal).then(response=>{
            var jsonData = response.data;
            this.termList = jsonData.data;
        });

        //查询年级
        companyInfoApi.treeList({companyId:newVal,type:"5"}).then(response=>{
            var jsonData = response.data;
            this.gradeList = jsonData.data;
        });
    }
    // stationSortList() {
    //   return this.formModel.stationList.sort((a,b) => {
    //     return a.sortNo - b.sortNo
    //   });
    // }
  },
  mounted: function() {
    this.changePage(1);

    setTimeout(()=>{
      this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
    },1000);
  },
  components: {
    "interestCourse-detail": InterestCourseDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>