<template>
  <div>
    <el-dialog
      width="80%"
      :visible.sync="showDialog"
      :title="title"
      :modal-append-to-body="true"
      :append-to-body="true"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
      <div class="user-panel" v-loading="loading">
        <el-form ref="form" :model="formModel" :rules="ruleValidate" label-width="100px" :inline="true">
          <el-form-item label="学校" prop="course.schoolId"> 
            <el-select v-model="formModel.course.schoolId" filterable placeholder="请选择" style="width:250px">
              <el-option
                v-for="result in schoolList"
                :key="result.id"
                :label="result.name"
                :value="result.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学期" prop="course.termId"> 
            <el-select v-model="formModel.course.termId" filterable placeholder="请选择" style="width:250px">
              <el-option
                v-for="result in termList"
                :key="result.id"
                :label="result.termName"
                :value="result.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称" prop="course.courseName">
            <el-input v-model="formModel.course.courseName" placeholder="请输入课程名称" style="width:250px"></el-input>
          </el-form-item>
          <el-form-item label="序号" prop="course.sortNo">
            <el-input-number v-model="formModel.course.sortNo" placeholder="请输入序号" style="width:250px"></el-input-number>
          </el-form-item>
          <el-form-item label="备注" prop="course.remark">
            <el-input v-model="formModel.course.remark" placeholder="请输入备注" style="width:250px"></el-input>
          </el-form-item>  
          <el-form-item label="是否开放报名" prop="course.active">
              <el-switch  v-model="formModel.course.active" active-color="#13ce66" inactive-color="#ff4949" @change="updateActive(row)"></el-switch>
          </el-form-item>  
          <h3>开放年级</h3>
          <ul class="bind-grade-list">
            <li v-for="(item,key) in formModel.gradeList" :key="key">
                <el-checkbox v-model="item.checked">{{item.gradeName}}</el-checkbox>
                <span>&nbsp;名额：</span>
                <el-input-number placeholder="招生人数" v-model="item.totalNum" size="mini" style="width:120px;"></el-input-number>
            </li>
          </ul>
          <h3>可选收费项管理</h3>
          <el-table :data="formModel.chargeList">
            <el-table-column type="index" label="序号" width="55"></el-table-column>
            <el-table-column prop="itemName" label="收费项目">
              <template slot-scope="scope">
                <el-form-item >
                  <el-input placeholder="收费项目" style="width:200px" v-model="scope.row.itemName" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="fee" label="收费金额(元)">
              <template slot-scope="scope">
                  <el-form-item>
                    <el-input-number placeholder="收费金额" style="width:120px" v-model="scope.row.fee" size="mini"></el-input-number>
                  </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="收费说明">
              <template slot-scope="{row}">
                <el-form-item>
                    <el-input placeholder="收费说明" style="width:200px" v-model="row.remark" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="{row}">
                <el-form-item>
                  <i class="el-icon-delete my-font" @click="handleDelete(row)"></i>
                  <i class="el-icon-top my-font" @click="handleUp(row)"></i>
                  <i class="el-icon-bottom my-font" @click="handleDown(row)"></i>
                  <i class="el-icon-document-add my-font" @click="handleInsert(row)"></i>
                </el-form-item>
              </template>
            </el-table-column>
            <div slot="empty">
              <el-button icon="el-icon-circle-plus-outline" :plain="true" @click="handleAdd()">新增</el-button>
            </div>
          </el-table>
        </el-form>
        <div class="el-table__empty-block" v-if="formModel.chargeList.length!=0">
          <el-button icon="el-icon-circle-plus-outline" @click="handleAdd()">新增</el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import interestCourseApi from "@/api/interest/interestCourse";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        courseName: [{ required: true, message: "课程名称不能为空", trigger: "blur" }],
        schoolId: [{ required: true, message: "学校不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      formModel: {
          course: {},
          gradeList: [],
          chargeList: [],
          removeChargeList: []
      },
      submitting: false,
      stationClassify: [],
      showMapDialog: false,
      selectedRow: null,
      schoolList: [],
      termList: []
    };
  },
  created() {
    var self = this;

    //查询学校
    companyInfoApi.treeList({type:"2"}).then(response => {
      var jsonData = response.data;
      this.schoolList = jsonData.data;
    });

    interestCourseApi.queryTermList(this.formModel.course.schoolId).then(response=>{
        var jsonData = response.data;
        this.termList = jsonData.data;
    });
  },
  watch: {
    "formModel.course.schoolId": function(newVal,oldVal) {
        //查询学期
        interestCourseApi.queryTermList(newVal).then(response=>{
            var jsonData = response.data;
            this.termList = jsonData.data;
        });

        var courseId = this.businessKey;

        //查询年级
        interestCourseApi.queryGradeList(courseId,newVal).then(response=>{
            var jsonData = response.data;
            this.formModel.gradeList = jsonData.data;
        });
    }
    // stationSortList() {
    //   return this.formModel.stationList.sort((a,b) => {
    //     return a.sortNo - b.sortNo
    //   });
    // }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {          
          self.loading = true;

          var chargeList = this.formModel.chargeList;

          for(var i=0;i<chargeList.length;i++){
            chargeList[i].sortNo = i+1;
          }

          (function() {
            var id = self.formModel.course.id;

            if (id == null || id.length == 0) {
              return interestCourseApi.add(self.formModel);
            } else {
              return interestCourseApi.update(self.formModel);
            }
          })().then(function(response) {
            self.loading = false;
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });
              self.$emit("close", {
                result: true,
                data: jsonData.data
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", {
                result: false
              });
            }
          });
        }
      });
    },
    handleAdd() {
      this.formModel.chargeList.push({
        sortNo: this.formModel.chargeList.length + 1,
        courseId: this.businessKey,
        itemName: "",
        fee: 0,
        remark: "",
        delFlag: 0
      });
    },
    handleDelete(row) {
      var chargeList = this.formModel.chargeList;

      chargeList.splice(chargeList.indexOf(row), 1);

      this.formModel.removeChargeList.push(row);
    },
    handleUp(row) {
      var chargeList = this.formModel.chargeList;
      var index = chargeList.indexOf(row);

      if(index>0){
        chargeList.splice(index, 1);
        chargeList.splice(index-1,0,row);
      }
    },
    handleDown(row) {
      var chargeList = this.formModel.chargeList;
      var index = chargeList.indexOf(row);

      if(index<chargeList.length-1){
        chargeList.splice(index, 1);
        chargeList.splice(index+1,0,row);
      }
    },
    handleInsert(row) {
      var chargeList = this.formModel.chargeList;
      var index = chargeList.indexOf(row);

      if(index>=0){
        chargeList.splice(index+1,0,{
          sortNo: index + 2,
          name: "",
          routeId: this.businessKey,
          classify: "",
          location: "",
          delFlag: 0
        });
      }
    }
  },
  async mounted() {
    console.log("mounted");

    var self = this;

    self.loading = true;

    (function() {
      if (self.businessKey.length == 0) {
        return interestCourseApi.create();
      } else {
        return interestCourseApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        self.loading = false;

        var jsonData = response.data;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.amap-demo {
  width: 100%;
  height: 400px;
}

.my-font {
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.bind-grade-list{
  list-style: none;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bind-grade-list li{
  margin-bottom:10px;
  margin-right:5px;
}
</style>